import * as React from 'react';

import Box from '@mui/joy/Box';
import Tab from '@mui/joy/Tab';
import Tabs from '@mui/joy/Tabs';
import Input from '@mui/joy/Input'
import TabList from '@mui/joy/TabList';
import TabPanel from '@mui/joy/TabPanel';
import CssBaseline from '@mui/joy/CssBaseline';
import { CssVarsProvider } from '@mui/joy/styles';

import Header from '../../Components/Header.tsx';

import AMP from './AMP/AMP.tsx'
import { Typography } from '@mui/joy';

export default function Administrative() {
	document.body.style.backgroundColor = '#d6d3ce';
	document.title = 'AMP'
	const tabStyle = {fontSize:'14px'}
	const inputStyle = { borderRadius: 0, px:1, mx: 1, height: 30, minHeight: 0, border:'1px solid black', color:'#000084', fontWeight:'bold', fontSize:'14px' };

	const selectedACFamily = sessionStorage.getItem('AMP_SelectedACFamily')
	const selectedOperator = sessionStorage.getItem('AMP_SelectedOperator')

	const [selectedTab, setSelectedTab] = React.useState (sessionStorage.getItem('AMP_screen')?sessionStorage.getItem('AMP_screen'):'AMP');
	const handleChangeTab = (newValue) => {
		sessionStorage.setItem('AMP_screen', newValue ? newValue : 'AMP');
		setSelectedTab(newValue);
	}

	React.useEffect(() => {
		if (!sessionStorage.getItem('AMP_screen')) {
			sessionStorage.setItem('AMP_screen','AMP');
		}
	}, []);
 

	return (
		<CssVarsProvider disableTransitionOnChange>
			<CssBaseline />
			<Box sx={{ display: 'flex', minHeight: '100dvh', minWidth:'1400px' }}>
				<Header title="Aircraft's Maintenance Program"/>
				<Box
					component="main"
					className="MainContent"
					sx={{
						px: 2,
						pt:'calc(var(--Header-height))',
						pb: 1,
						flex: 1,
						display: 'flex',
						flexDirection: 'column',
						minWidth: 0,
						height: '100dvh',
						gap: 1,
					}}
				>
					<Box>
						<Box
							sx={{
								height:'50px',
								display:'flex',
								position:'relative',
								alignItems:'flex-end',
								justifyContent:'center',
							}}
						>
							<Box
								sx={{
									border:'1px solid black',
									borderBottom:'none',
									height:'auto',
									width:'auto',
									display:'flex',
									alignItems:'center',
									p:0.5,
									position:'relative'
								}}
							>
								<Box
									sx={{
										color:'#000084',
										position:'absolute',
										top:-8,
										fontSize:10,
										bgcolor:'#d6d3ce'
									}}
								>
									Selected AMP:
								</Box>
								<Typography>
									AC Family:
								</Typography>
								<Input  
									sx={inputStyle} 
									value={selectedACFamily || ''}
									
								/>
								<Input 
									disabled
									sx={{
										...inputStyle, 
										'--variant-outlinedDisabledColor':'black',
										'--variant-outlinedDisabledBorder':'black',
										bgcolor:'#d6d3ce',
									}} 
									value={selectedOperator?.split('/')[0] || ''}
								/>
								<Input 
									disabled
									sx={{
										...inputStyle, 
										'--variant-outlinedDisabledColor':'black',
										'--variant-outlinedDisabledBorder':'black',
										bgcolor:'#d6d3ce',
									}} 
									value={selectedOperator?.split('/')[1] || ''}
								/>
							</Box>
						</Box>
						<Tabs
							value={selectedTab}
							onChange={(event, newValue) => handleChangeTab(newValue)}
							sx={{
								flexDirection: 'column',
								bgcolor:'#d6d3ce',
								whiteSpace: 'nowrap',
								minHeight:'830px',
								border:'1px solid black',
							}}
						>
							<TabList >
								<Tab value="AMP" sx={tabStyle}>
									AMP
								</Tab>
								<Tab value="PS" sx={tabStyle}>
									AMP Pos Struct
								</Tab>
								<Tab value="MR" sx={tabStyle}>
									AMP MR
								</Tab>
								<Tab value="M" sx={tabStyle}>
									AMP Model
								</Tab>
								<Tab value="P" sx={tabStyle}>
									AMP Plan
								</Tab>
								<Tab value="PAM" sx={tabStyle}>
									POS-AMP MR
								</Tab>
								<Tab value="TE" sx={tabStyle}>
									Task Effectivity
								</Tab>
								<Tab value="MRB" sx={tabStyle}>
									MRB Category
								</Tab>
							</TabList>
							<TabPanel value="AMP" sx={{p:'0px'}}>
								<AMP selectedACFamily={selectedACFamily} selectedOperator={selectedOperator}/>
							</TabPanel>
							<TabPanel value="PS" sx={{p:'0px'}}>
								
							</TabPanel>
						</Tabs>
					</Box>		
				</Box>
			</Box>
		</CssVarsProvider>
	);
}
