import * as React from 'react';

import Tooltip from '@mui/joy/Tooltip';
import IconButton from '@mui/joy/IconButton';
import Box from '@mui/joy/Box';


export default function JoyOrderDashboardTemplate() {
  	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				justifyContent: 'center',
				height: '100vh',

			}}
		>
			<Box
				sx={{
					display:'flex',
					flexDirection:'row',
					alignItems:'center',
				}}
			>
				<Box
					sx={{
						display:'flex',
						flexDirection:'column',
						alignItems:'center',
					}}
				>
					<Tooltip title='Reset Filters'>
						<IconButton
							variant='plain'
							sx={{
								m:0,
							}}
						>
							<img 
								width='80px' 
								height='80px' 
								src="images/svg/ExcelVersions/excelMy.svg" 
								alt="" 
							/>
						</IconButton>
					</Tooltip>
					V1
				</Box>
				<Box
					sx={{
						display:'flex',
						flexDirection:'column',
						alignItems:'center',
					}}
				>
					<Tooltip title='Reset Filters'>
						<IconButton
							variant='plain'
							sx={{
								m:0,
							}}
						>
							<img 
								width='80px' 
								height='80px' 
								src="images/svg/ExcelVersions/excelGreenLater.svg" 
								alt="" 
							/>
						</IconButton>
					</Tooltip>
					V2
				</Box>
				<Box
					sx={{
						display:'flex',
						flexDirection:'column',
						alignItems:'center',
					}}
				>
					<Tooltip title='Reset Filters'>
						<IconButton
							variant='plain'
							sx={{
								m:0,
							}}
						>
							<img 
								width='80px' 
								height='80px' 
								src="images/svg/ExcelVersions/excelGreenRows.svg" 
								alt="" 
							/>
						</IconButton>
					</Tooltip>
					V3
				</Box>
			</Box>
			<Box
				sx={{
					display:'flex',
					flexDirection:'row',
					alignItems:'center',
				}}
			>
				<Box
					sx={{
						display:'flex',
						flexDirection:'column',
						alignItems:'center',
					}}
				>
					<Tooltip title='Reset Filters'>
						<IconButton
							variant='plain'
							sx={{
								m:0,
							}}
						>
							<img 
								width='80px' 
								height='80px' 
								src="images/svg/ExcelVersions/excelFullGreen.svg" 
								alt="" 
							/>
						</IconButton>
					</Tooltip>
					V4
				</Box>
				<Box
					sx={{
						display:'flex',
						flexDirection:'column',
						alignItems:'center',
					}}
				>
					<Tooltip title='Reset Filters'>
						<IconButton
							variant='plain'
							sx={{
								m:0,
							}}
						>
							<img 
								width='80px' 
								height='80px' 
								src="images/svg/ExcelVersions/excelGreenBgWhiteTxt.svg" 
								alt="" 
							/>
						</IconButton>
					</Tooltip>
					V5
				</Box>
				<Box
					sx={{
						display:'flex',
						flexDirection:'column',
						alignItems:'center',
					}}
				>
					<Tooltip title='Reset Filters'>
						<IconButton
							variant='plain'
							sx={{
								m:0,
							}}
						>
							<img 
								width='80px' 
								height='80px' 
								src="images/svg/ExcelVersions/excelGreenBgBlueTxt.svg" 
								alt="" 
							/>
						</IconButton>
					</Tooltip>
					V6
				</Box>
			</Box>
		</div>
  	);
}