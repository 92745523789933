import React from 'react';
import Module from './components/Modules/Main.tsx';
import Development from './components/Development.tsx';
import Main from './components/Main_page/Main_page.tsx';
import JoySignInSideTemplate from './components/LoginForm.tsx';
import PartM from './components/Modules/Modules/Part-M/Main.tsx';
import AMP from './components/Modules/Modules/Part-M/AMP/Main.tsx'
import Store from './components/Modules/Modules/Store/Store/Store.tsx';
import Admin_Module from './components/Modules/Modules/Admin_module/Main.tsx'

import Test from './Test/test.tsx';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

function App() {
	const asid = localStorage.getItem('asid');

	return (
		<BrowserRouter>
			<Routes>
				{/*<Route path="/SignIn" element={localStorage.getItem('asid') ? <Navigate to="/" replace /> : <JoySignInSideTemplate />} />*/}
				<Route path="/SignIn" element={<JoySignInSideTemplate/>} />
				<Route path="/" element={<Main />} />
				<Route path="/:module" element={<Module />} />
				<Route path="/:module/Store" element={<Store />} />
				<Route path="/Part-M" element={<PartM />} />
				<Route path="/AMP" element={<AMP />} />
				<Route path="/Administrative" element={<Admin_Module />} />
				<Route path="/test" element={<Test />} />
				<Route path="/:module/*" element={<Development />} />
			</Routes>
		</BrowserRouter>
	);
};

export default App;

