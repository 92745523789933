import * as React from 'react';

//Компоненты библиотеки @mui/joy
import Box from '@mui/joy/Box';
import Table from '@mui/joy/Table';
import Sheet from '@mui/joy/Sheet';
import Input from '@mui/joy/Input';
import Tooltip from '@mui/joy/Tooltip';
import Checkbox from '@mui/joy/Checkbox';
import Typography from '@mui/joy/Typography';
import IconButton from '@mui/joy/IconButton';
import Autocomplete from '@mui/joy/Autocomplete';

//Иконки
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

//Компоненты 
import AddEditeForm from './AddEditeForm';
import ErrorSuccessfulResponse from '../../../Components/ErrorSuccessfullResponse.tsx';

import URL from '../../../../../../URL.js';

export default function Aircraft_Actual() {

	//Стили
	const inputStyle = {borderRadius:'0px', minHeight:'0px', height:'26px', border:'1px solid black',color:'#000078', fontWeight:'bold',fontSize:'14px', mb:1, width:'210px'};
	const headerStyle = {width: 'auto',fontWeight:'normal',fontSize:'16px',color:'black',};
	const rowStyle = {whiteSpace: 'nowrap', width: 'auto',fontSize:'18px'};
	const filtersStyle = {position:'absolute',width:'100%',top:0,minWidth:0,minHeight:0,height:'100%',px: 0.5,m: 0,borderRadius: '0px',"--Input-decoratorChildHeight": "24px",};

	const headers =[{id:'serialNumber',name:'AC_SN'}, {id:'reg',name:'AC_Reg'},{id:'previousReg',name:'AC_Reg_Previous'},{id:'deliverDate',name:'AC_Deliver_Date'},{id:'deliverHours',name:'AC_Deliver_Hours'},{id:'deliverCycles',name:'AC_Deliver_Cycles'},{id:'pax',name:'PAX'},{id:'mtow',name:'MTOW'},{id:'certifiedTOW',name:'Certificated_TOW'},{id:'mtxw',name:'MTXW'},{id:'selcalCode',name:'SELCAL_Code'},{id:'modeAddress',name:'Mode_S_Address'},{id:'dfdauFrame',name:'DFDAU_Frame'},{id:'atcCode',name:'ATC_Code'},{id:'operatorICAO',name:'Operator_Code_ICAO'},{id:'operatorName',name:'Operator_Name'},{id:'operatorStaLocation',name:'STA_Operator_Base_Location'},{id:'aircraftStaLocation',name:'STA_Aircraft_Location'},{id:'operatorICAOPrevious',name:'Operator_Code_ICAO_Previous'},{id:'operatorNamePrevious',name:'Operator_Name_Previous'},{id:'mroCode',name:'MRO_Code'},{id:'mroName',name:'MRO_Name'},{id:'staMRO',name:'STA_MRO'},{id:'engineeringCode',name:'Engineering_Code'},{id:'engineeringName',name:'Engineering_Name'},{id:'engineeringSta',name:'STA_Engineering'},{id:'engineModel',name:'Engine_Model'},{id:'engineThrust',name:'Engine_Thrust'},{id:'apuModel',name:'APU_Model'},{id:'acAccount',name:'AC_Account'},{id:'componentAccount',name:'Component_Account'},{id:'acOwner',name:'AC_Owner'},{id:'componentOwner',name:'Component_Owner'},{id:'remarks',name:'Remarks'},{id:'active',name:'Active'},{id:'tcDocNumber',name:'TC Doc. Number'},{id:'manuals',name:'Manuals'},{id:'manualsPath',name:'Manuals_Path'},{id:'isLumpActive',name:'IsLumpActive'}]

	const [data, setData] = React.useState<{id:number, serialNumber:string, reg:string, previousReg:string, deliverDate:string, deliverHours:number, deliverCycles:number, pax:string, mtow:string, certifiedTOW:string, mtxw:string, selcalCode:string, modeAddress:string, dfdauFrame:string, atcCode:string, operatorICAO:string, operatorName:string, operatorStaLocation:string, aircraftStaLocation:string, operatorICAOPrevious:string, operatorNamePrevious:string, mroCode:string, mroName:string, staMRO:string, engineeringCode:string, engineeringName:string, engineeringSta:string, engineModel:string, engineThrust:string, apuModel:string, acAccount:string, componentAccount:string, acOwner:string, componentOwner:string, remarks:string, active:string, tcDocNumber:string, manuals:boolean, manualsPath:string, isLumpActive:null, reportsLogoName:string}[]>([]);
	const [filteredData, setFilteredData] = React.useState <{id:number, serialNumber:string, reg:string, previousReg:string, deliverDate:string, deliverHours:number, deliverCycles:number, pax:string, mtow:string, certifiedTOW:string, mtxw:string, selcalCode:string, modeAddress:string, dfdauFrame:string, atcCode:string, operatorICAO:string, operatorName:string, operatorStaLocation:string, aircraftStaLocation:string, operatorICAOPrevious:string, operatorNamePrevious:string, mroCode:string, mroName:string, staMRO:string, engineeringCode:string, engineeringName:string, engineeringSta:string, engineModel:string, engineThrust:string, apuModel:string, acAccount:string, componentAccount:string, acOwner:string, componentOwner:string, remarks:string, active:string, tcDocNumber:string, manuals:boolean, manualsPath:string, isLumpActive:null, reportsLogoName:string}[]>([]);
	const [selectedRow, setSelectedRow] = React.useState({id:0});
	const [filters, setFilters] = React.useState({id:0, serialNumber:'', reg:'', previousReg:'', deliverDateFrom:'', deliverDateTo:'', deliverHours:'', deliverCycles:'', pax:'', mtow:'', certifiedTOW:'', mtxw:'', selcalCode:'', modeAddress:'', dfdauFrame:'', atcCode:'', operatorICAO:'', operatorName:'', operatorStaLocation:'', aircraftStaLocation:'', operatorICAOPrevious:'', operatorNamePrevious:'', mroCode:'', mroName:'', staMRO:'', engineeringCode:'', engineeringName:'', engineeringSta:'', engineModel:'', engineThrust:'', apuModel:'', acAccount:'', componentAccount:'', acOwner:'', componentOwner:'',  remarks:'', active:'Y', tcDocNumber:'', manuals:true, manualsPath:'', isLumpActive:null, reportsLogoName:''});
	const [showFilters, setShowFilters] = React.useState(false);
	const [sort, setSort] = React.useState ({lable:'id', order:'asc'});
	const [SN, setSN] = React.useState ([]);
    const [operators, setOperators] = React.useState ([{}]);
    const [MRO, setMRO] = React.useState ([{}]);
	const [sta, setSta] = React.useState ([{}]);
	const [showEditor, setShowEditor] = React.useState (true);
	const [openAdditionalInfo, setOpenAdditionalInfo] = React.useState(false);

	const [responseStatus, setResponseStatus] = React.useState({errorStatus:0, successfulStatus:''})

	//Запрос данных для таблицы
	const fetchData = async () => {
		const response = await fetch(`${URL}${process.env.REACT_APP_API_ADMIN}/ActualAircrafts`, 
		{ headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
		if (response.ok) {
			const result = await response.json();
			setData(result.value);
		} else {
			setResponseStatus({...responseStatus, errorStatus:response.status});
		}
	};

	//Запрос списка Serial Numbers
	const fetchSN = async () => {
		const response = await fetch(`${URL}${process.env.REACT_APP_API_ADMIN}/AircraftRegistrations/FreeSerialNumbers()`,
		{ headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
		if (response.ok) {	
			const result = await response.json();
			setSN(result.value);
		} else {
			setResponseStatus({...responseStatus, errorStatus:response.status});
		}
	};

	//Запрос списка Operators ICAO
	const fetchOperators = async () => {
		const response = await fetch(`${URL}${process.env.REACT_APP_API_ADMIN}/OperatorRegistrations?&apply=groupby((icao,name))`, 
		{ headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
		if (response.ok) {	
			const result = await response.json();
			setOperators(result);
		} else {
			setResponseStatus({...responseStatus, errorStatus:response.status});
		}
	};
	

    //Запрос списка MRO
	const fetchMRO= async () => {
		const response = await fetch(`${URL}${process.env.REACT_APP_API_ADMIN}/MroRegistrations?$apply=groupby((code,name,sta))`, 
		{ headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
		if (response.ok) {	
			const result = await response.json();
			setMRO(result);
		} else {
			setResponseStatus({...responseStatus, errorStatus:response.status});
		}
	};

	//Запрос списка Sta
	const fetchSta= async () => {
		const response = await fetch(`${URL}${process.env.REACT_APP_API_ADMIN}/StaRegistrations?$filter=activate eq 'Y'&$select=code,name`, 
		{ headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
		if (response.ok) {	
			const result = await response.json();
			setSta(result.value);
		} else {
			setResponseStatus({...responseStatus, errorStatus:response.status});
		}
	};
	
	React.useEffect(() => {
		fetchData();
		fetchSN();
		fetchOperators();
		fetchMRO();
		fetchSta();
	}, []);

	//Выбор строки
	const handleRowSelection = (row) => {
		if(row.id === selectedRow.id){
			setSelectedRow({id:0});
		} else {
			setSelectedRow(row);
		}  
	};
	
	//Фильтрация данных
	React.useEffect(() => {
		let filtered = data;

		for (const key in filters) {
			if(key != 'active' && key != 'deliverDateFrom' && key != 'deliverDateTo' && key != 'manuals' && key != 'isLumpActive' && filters[key] != ''){
				filtered = filtered.filter(row => row[key]?.toString().toLowerCase().includes(filters[key].toString().toLowerCase()))
			} else {
				if (filters.active == 'Y') {filtered = filtered.filter(row => row.active == filters.active)}
				if (filters.deliverDateFrom != '') {filtered = filtered.filter(row => row.deliverDate?.slice(0, 10) >= filters.deliverDateFrom)}
				if (filters.deliverDateTo != '') {filtered = filtered.filter(row => row.deliverDate?.slice(0, 10) <= filters.deliverDateTo)}
			}
		}
        setFilteredData(filtered);
    }, [data, filters]);

	//Сброс фильтров
	const refreshFilters = () => {
		setFilters({id:0, serialNumber:'', reg:'', previousReg:'', deliverDateFrom:'', deliverDateTo:'', deliverHours:'', deliverCycles:'', pax:'', mtow:'', certifiedTOW:'', mtxw:'', selcalCode:'', modeAddress:'', dfdauFrame:'', atcCode:'', operatorICAO:'', operatorName:'', operatorStaLocation:'', aircraftStaLocation:'', operatorICAOPrevious:'', operatorNamePrevious:'', mroCode:'', mroName:'', staMRO:'', engineeringCode:'', engineeringName:'', engineeringSta:'', engineModel:'', engineThrust:'', apuModel:'', acAccount:'', componentAccount:'', acOwner:'', componentOwner:'',  remarks:'', active:'Y', tcDocNumber:'', manuals:true, manualsPath:'', isLumpActive:null, reportsLogoName:''});
	}

	//Изменение фильтров
	const handleChange = (property, value) => {
		setFilters({ ...filters, [property]: value });
	};

	//Сортировка данных
	const handleSort = (property, value) => {
		let filtered = filteredData
		if (property === 'id' || property === 'deliverHours' || property === 'deliverCycles'){
			filtered = filtered.sort((a, b) => value === 'asc' ? a[property] - b[property] : b[property]- a[property]);
		} else {
			filtered = filtered.sort((a, b) => value === 'asc' ? a[property]?.localeCompare(b[property]) : b[property]?.localeCompare(a[property]));
		}
		setFilteredData(filtered)
		setSort({lable:property, order:value})
	};

	//Изменение чекбокса Activate
	const handleCheckboxChange = (event) => {
		const activateValue = event.target.checked ? 'Y' : 'N';
		handleChange('active', activateValue);
	};

	//Проверка наличия фильтров
	const checkFilters = () => {
		const initialState = {id:0, serialNumber:'', reg:'', previousReg:'', deliverDateFrom:'', deliverDateTo:'', deliverHours:'', deliverCycles:'', pax:'', mtow:'', certifiedTOW:'', mtxw:'', selcalCode:'', modeAddress:'', dfdauFrame:'', atcCode:'', operatorICAO:'', operatorName:'', operatorStaLocation:'', aircraftStaLocation:'', operatorICAOPrevious:'', operatorNamePrevious:'', mroCode:'', mroName:'', staMRO:'', engineeringCode:'', engineeringName:'', engineeringSta:'', engineModel:'', engineThrust:'', apuModel:'', acAccount:'', componentAccount:'', acOwner:'', componentOwner:'',  remarks:'', active:'Y', tcDocNumber:'', manuals:true, manualsPath:'', isLumpActive:null, reportsLogoName:''};
		return Object.keys(initialState).every(key => filters[key] === initialState[key]);
	}

	//Открытие и закрытие Editore
	const toggleEditore = () => {
		setShowEditor(prevState => !prevState);
	}
	
	//Создание Excel отчёта
	const createExcel = () => {
		return fetch(`${URL}${process.env.REACT_APP_API_ADMIN}/Users/Xlsx(categories=${[1]})`, {
			headers: {
				'Authorization': `Bearer ${localStorage.getItem('asid')}`,
				'Content-Type': 'application/vnd.ms-excel'
			},
		})
		.then((response) => {
			if (response.ok) {
				return response.blob();
			} else {
				setResponseStatus({...responseStatus, errorStatus:response.status});
			}
		})
		.then((blob) => {
			if (blob) {
				const url = window.URL.createObjectURL(blob); 
				const link = document.createElement('a'); 
				link.href = url;
				link.setAttribute('download', 'Security_Users.xlsx'); 
				document.body.appendChild(link); 
				link.click();
				link.remove(); 
				window.URL.revokeObjectURL(url);
			}
		})
		.catch((error) => {
			console.error('Ошибка при получении файла:', error);
		});
	};

	//Создание PDF отчёта
	{/*const createReport = () => {
		setReportLoading(true);
		return fetch(`${URL}/reports/Reports`, {
			method: 'POST',
			headers: {
				'Authorization': `Bearer ${localStorage.getItem('asid')}`,
				'Content-Type': 'application/json'
			},
			body:JSON.stringify({
				"templateName": "Templates/PostgreSQL/Admin/UserRegistrationList",
				"isExportAsPicture": false,
				"parameters": [
					{
						"name": 'userId',
						"value": `${filters.login}`
					},
					{
						"name": 'name',
						"value": `${filters.name}`
					},
					{
						"name": 'code',
						"value": `${filters.code}`
					},
					{
						"name": 'userGroup',
						"value": `${filters.userGroup}`
					},
					{
						"name": 'licence',
						"value": `${filters.licenceNum}`
					},
					{
						"name": 'skill',
						"value": `${filters.skill}`
					},
					{
						"name": 'ac',
						"value": ``
					},
					{
						"name": 'engCategories',
						"value": ``
					},
					{
						"name": 'secGroup',
						"value": `${filters.securityGroup}`
					},
					{
						"name": 'active',
						"value": `${filters.activate}`
					},
					{
						"name": 'storeDbName',
						"value": `Store`
					},
				]
			})
		})
		.then((response) => {
			if (response.ok) {
				return response.blob();
			} else {
				errors(response.status);
				setReportLoading(false);
			}
		})
		.then((blob) => {
			if (blob) {
				const url = window.URL.createObjectURL(blob); 
				const link = document.createElement('a'); 
				link.href = url;
				link.setAttribute('download', 'UserRegistrationList.pdf'); 
				document.body.appendChild(link); 
				link.click();
				link.remove(); 
				window.URL.revokeObjectURL(url);

				setReportLoading(false);
			} else {
				setReportLoading(false);
			}
		})
		.catch((error) => {
			console.error('Ошибка при получении файла:', error);
			setReportLoading(false);
		});
	};
*/}

	return (
		<React.Fragment>
			<ErrorSuccessfulResponse responseStatus={responseStatus} setResponseStatus={setResponseStatus} />
			<Box
				sx={{
					display:'grid',
					gridTemplateColumns: showEditor ? '50% 50%' : '1fr',
				}}
			>
				<Box
					component="main"
					className="MainContent"
					sx={{
						px:1,
						pb: 1,
						flex: 1,
						display: 'flex',
						flexDirection: 'column',
						minWidth: 0,
						height: '85dvh',
						position:'relative'
					}}
				>
					<Box
						justifyContent="center"
						alignItems="center"
						sx={{
							position:'absolute',
							right:0,
							mt:0.5
						}}
					>
						<Tooltip title='Create Excel Report'>
							<IconButton
								variant='plain'
								onClick={createExcel}
								sx={{
									m:0,
								}}
							>
								<img 
									width='35px' 
									src="images/svg/excel.svg" 
									alt="" 
								/>
							</IconButton>
						</Tooltip>
						<Tooltip title='Reset Filters'>
							<IconButton
								variant='plain'
								onClick={refreshFilters}
								sx={{
									m:0,
								}}
							>
								<img 
									width='35px' 
									src="images/svg/refresh.svg" 
									alt="" 
								/>
							</IconButton>
						</Tooltip>
						<Tooltip title= {showEditor? 'Hide Editor' : 'Show Editor'}>
							<IconButton
								variant='plain'
								onClick={toggleEditore} 
								sx={{
									m:0,
									transition: '0.3s',
									transform: showEditor? 'rotate(0deg)' : 'rotate(180deg)',
								}}
							>
								<img 
									width='35px' 
									src="images/svg/arrowBack.svg" 
									alt="" 
								/>
							</IconButton>
						</Tooltip>
					</Box>	
					<Typography 
						sx={{ 
							fontSize:'16px',
							fontWeight:'bold',
							color:'#000078',
							fontFamily:'Arial, sans-serif'
						}}
					>
						Aircraft Actual Registration List:
					</Typography>			
					<Box
						className="SearchAndFilters-tabletUp"
						sx={{
							display:'flex',
							flexWrap: 'nowrap',
							gap: 1.5,
							alignItems:'flex-end'
						}}
					>
                        <Typography
                            sx={{
                                mb:1,
                            }}
                        >
                            AC Reg:
                        </Typography>
                        <Autocomplete
                            options={[...new Set(data.map(data => data.reg))].filter(row => row).map(reg => ({ label: reg }))}
                            sx={{
                                ...inputStyle,
                                "--Input-decoratorChildHeight": "24px"
                            }}
                            onChange={(event, value) => handleChange('reg', value ? value.label : '')}
                            value={filters.reg === '' ? null : { label: filters.reg }}
                        />
						<Checkbox 
							variant="outlined"
							color="neutral" 
							label="Active Only"
							checked={filters.active == 'Y' ? true:false}
							onChange={handleCheckboxChange}
							sx={{
								mb:1,
								'& .css-r4cpcz-JoyCheckbox-checkbox': {
									borderRadius:0,
									'--joy-palette-neutral-outlinedBorder':'black',
								},
								'& .css-n110n' : {
									borderRadius:0,
									'--joy-palette-neutral-outlinedBorder':'black',
								},
							}}
						/>
					</Box>
					<Box
						onClick={() => setShowFilters(!showFilters)}
						sx={{
							border:'1px solid black',
							borderBottom:'none',
							height:'24px',
							display:'flex',
							alignItems:'center',
							justifyContent:'center',
							bgcolor:checkFilters()?'transparent':'#DDE7EE'
						}}
					>
						<ArrowDropDownIcon 
							sx={{
								fontSize: '20px',
								transition: '0.2s',
								transform: !showFilters ? 'rotate(0deg)' : 'rotate(180deg)',
							}}
						/>
					</Box>
					<Sheet
						variant="outlined"
						sx={{
							display:'initial',
							width: '100%',
							borderRadius: '0px',
							flexShrink: 1,
							overflow: 'auto',
							minHeight: '700px',
							border: '1px solid black',
							bgcolor:'#848284'
						}}
					>
						<Table
							borderAxis="both"
							stickyHeader
							hoverRow
							sx={{
								'--TableCell-headBackground': '#d6d3ce',
								'--Table-headerUnderlineThickness': '1px',
								'--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
								'--TableCell-paddingY': '1px',
								'--TableCell-height': '28px',
								'--TableCell-paddingX': '5px',
								'--TableCell-borderColor': '#939293',
								tableLayout: 'auto', 
								width:'auto',
								borderRadius:'0px',
								lineHeight:0
							}}
						>
							<thead>
								<tr>
									<th style={headerStyle}>
										<Typography
											onClick={() => handleSort('id', sort.order == 'asc' ? 'desc':'asc' )}
											endDecorator={<ArrowDropDownIcon />}
											sx={{
												'& svg': {
													color:sort.lable === 'id' ? '#636b74' : 'transparent',
													transition: '0.2s',
													transform:sort.order === 'asc' ? 'rotate(0deg)' : 'rotate(180deg)',
												},
												fontWeight:filters.id != 0 ? 'bold' : 'normal'
											}}
										>
											ID
										</Typography>
									</th>
									{headers.map((row) => (
										<th style={headerStyle}>
											<Typography
												onClick={() => handleSort(row.id, sort.order == 'asc' ? 'desc':'asc' )}
												endDecorator={<ArrowDropDownIcon />}
												sx={{
													'& svg': {
														color:sort.lable === row.id ? '#636b74' : 'transparent',
														transition: '0.2s',
														transform: sort.order === 'asc' ? 'rotate(0deg)' : 'rotate(180deg)',
													},
													fontWeight:filters[row.id] ? 'bold' : 'normal'
												}}
											>
												{row.name}
											</Typography>
										</th>
									))}
								</tr>
								{showFilters && (
									<tr>
										<th style={{padding:0}}>
											<Input
												value={filters.id}
												onChange={(event) => handleChange('id', event.target.value)}
												type="number"
												slotProps={{
													input: {
														step: 1,
														min:0
													}}}
												sx={filtersStyle}
											/>
										</th>
										<th style={{padding:0}}>
											<Input
												value={filters.serialNumber}
												onChange={(event) => handleChange('serialNumber', event.target.value)}
												sx={filtersStyle}
											/>
										</th>
										<th style={{padding:0}}>
											<Input
												value={filters.reg}
												onChange={(event) => handleChange('reg', event.target.value)}
												sx={filtersStyle}
											/>
										</th>
										<th style={{padding:0}}>
											<Input
												value={filters.previousReg}
												onChange={(event) => handleChange('previousReg', event.target.value)}
												sx={filtersStyle}
											/>
										</th>
										<th style={{padding:0}}>
											<Box
												sx={{
													display:'flex',
													alignItems:'center'
												}}
											>
												From:
												<Input
													type="date"
													sx={{
														width:'130px',
														minWidth:'0px',
														height:'100%',
														pl:0.5,
														borderRadius:'0px',
														ml:0.2,
														mr:2
													}}
													value={filters.deliverDateFrom}  
													onChange={(event) => handleChange('deliverDateFrom', event.target.value)}
												/>
												-
												To:
												<Input
													type="date"
													sx={{
														width:'130px',
														minWidth:'0px',
														height:'100%',
														pl:0.2,
														borderRadius:'0px',
														ml:0.2,
													}}
													value={filters.deliverDateTo} 
													onChange={(event) => handleChange('deliverDateTo', event.target.value)}
												/>
											</Box>
										</th>
										<th style={{padding:0}}>
											<Input
												value={filters.deliverHours}
												onChange={(event) => handleChange('deliverHours', event.target.value)}
												type="number"
												slotProps={{
													input: {
														step: 1,
														min:0
													}}}
												sx={filtersStyle}
											/>
										</th>
										<th style={{padding:0}}>
											<Input
												value={filters.deliverCycles}
												onChange={(event) => handleChange('deliverCycles', event.target.value)}
												type="number"
												slotProps={{
													input: {
														step: 1,
														min:0
													}}}
												sx={filtersStyle}
											/>
										</th>
										<th style={{padding:0}}>
											<Input
												value={filters.pax}
												onChange={(event) => handleChange('pax', event.target.value)}
												sx={filtersStyle}
											/>
										</th>
										<th style={{padding:0}}>
											<Input
												value={filters.mtow}
												onChange={(event) => handleChange('mtow', event.target.value)}
												sx={filtersStyle}
											/>
										</th>
										<th style={{padding:0}}>
											<Input
												value={filters.certifiedTOW}
												onChange={(event) => handleChange('certifiedTOW', event.target.value)}
												sx={filtersStyle}
											/>
										</th>
										<th style={{padding:0}}>
											<Input
												value={filters.mtxw}
												onChange={(event) => handleChange('mtxw', event.target.value)}
												sx={filtersStyle}
											/>
										</th>
										<th style={{padding:0}}>
											<Input
												value={filters.selcalCode}
												onChange={(event) => handleChange('selcalCode', event.target.value)}
												sx={filtersStyle}
											/>
										</th>
										<th style={{padding:0}}>
											<Input
												value={filters.modeAddress}
												onChange={(event) => handleChange('modeAddress', event.target.value)}
												sx={filtersStyle}
											/>
										</th>
										<th style={{padding:0}}>
											<Input
												value={filters.dfdauFrame}
												onChange={(event) => handleChange('dfdauFrame', event.target.value)}
												sx={filtersStyle}
											/>
										</th>
										<th style={{padding:0}}>
											<Input
												value={filters.atcCode}
												onChange={(event) => handleChange('atcCode', event.target.value)}
												sx={filtersStyle}
											/>
										</th>
										<th style={{padding:0}}>
											<Autocomplete
												options={[...new Set(data.map(data => data.operatorICAO))].filter(row => row).map(operatorICAO => ({ label: operatorICAO}))}
												onChange={(event, value) => handleChange('operatorICAO', value ? value.label : '')}
												value={filters.operatorICAO === '' ? null : { label: filters.operatorICAO }}
												sx={filtersStyle}
											/>
										</th>
										<th style={{padding:0}}>
											<Autocomplete
												options={[...new Set(data.map(data => data.operatorName))].filter(row => row).map(operatorName => ({ label: operatorName}))}
												onChange={(event, value) => handleChange('operatorName', value ? value.label : '')}
												value={filters.operatorName === '' ? null : { label: filters.operatorName }}
												sx={filtersStyle}
											/>
										</th>
										<th style={{padding:0}}>
											<Autocomplete
												options={[...new Set(data.map(data => data.operatorStaLocation))].filter(row => row).map(operatorStaLocation => ({ label: operatorStaLocation}))}
												onChange={(event, value) => handleChange('operatorStaLocation', value ? value.label : '')}
												value={filters.operatorStaLocation === '' ? null : { label: filters.operatorStaLocation }}
												sx={filtersStyle}
											/>
										</th>
										<th style={{padding:0}}>
											<Autocomplete
												options={[...new Set(data.map(data => data.aircraftStaLocation))].filter(row => row).map(aircraftStaLocation => ({ label: aircraftStaLocation}))}
												onChange={(event, value) => handleChange('aircraftStaLocation', value ? value.label : '')}
												value={filters.aircraftStaLocation === '' ? null : { label: filters.aircraftStaLocation }}
												sx={filtersStyle}
											/>
										</th>
										<th style={{padding:0}}>
											<Autocomplete
												options={[...new Set(data.map(data => data.operatorICAOPrevious))].filter(row => row).map(operatorICAOPrevious => ({ label: operatorICAOPrevious}))}
												onChange={(event, value) => handleChange('operatorICAOPrevious', value ? value.label : '')}
												value={filters.operatorICAOPrevious === '' ? null : { label: filters.operatorICAOPrevious }}
												sx={filtersStyle}
											/>
										</th>
										<th style={{padding:0}}>
											<Autocomplete
												options={[...new Set(data.map(data => data.operatorNamePrevious))].filter(row => row).map(operatorNamePrevious => ({ label: operatorNamePrevious}))}
												onChange={(event, value) => handleChange('operatorNamePrevious', value ? value.label : '')}
												value={filters.operatorNamePrevious === '' ? null : { label: filters.operatorNamePrevious }}
												sx={filtersStyle}
											/>
										</th>
										<th style={{padding:0}}>
											<Autocomplete
												options={[...new Set(data.map(data => data.mroCode))].filter(row => row).map(mroCode => ({ label: mroCode}))}
												onChange={(event, value) => handleChange('mroCode', value ? value.label : '')}
												value={filters.mroCode === '' ? null : { label: filters.mroCode }}
												sx={filtersStyle}
											/>
										</th>
										<th style={{padding:0}}>
											<Autocomplete
												options={[...new Set(data.map(data => data.mroName))].filter(row => row).map(mroName => ({ label: mroName}))}
												onChange={(event, value) => handleChange('mroName', value ? value.label : '')}
												value={filters.mroName === '' ? null : { label: filters.mroName }}
												sx={filtersStyle}
											/>
										</th>
										<th style={{padding:0}}>
											<Autocomplete
												options={[...new Set(data.map(data => data.staMRO))].filter(row => row).map(staMRO => ({ label: staMRO}))}
												onChange={(event, value) => handleChange('staMRO', value ? value.label : '')}
												value={filters.staMRO === '' ? null : { label: filters.staMRO }}
												sx={filtersStyle}
											/>
										</th>

										<th style={{padding:0}}>
											<Autocomplete
												options={[...new Set(data.map(data => data.engineeringCode))].filter(row => row).map(engineeringCode => ({ label: engineeringCode}))}
												onChange={(event, value) => handleChange('engineeringCode', value ? value.label : '')}
												value={filters.engineeringCode === '' ? null : { label: filters.engineeringCode }}
												sx={filtersStyle}
											/>
										</th>
										<th style={{padding:0}}>
											<Autocomplete
												options={[...new Set(data.map(data => data.engineeringName))].filter(row => row).map(engineeringName => ({ label: engineeringName}))}
												onChange={(event, value) => handleChange('engineeringName', value ? value.label : '')}
												value={filters.engineeringName === '' ? null : { label: filters.engineeringName }}
												sx={filtersStyle}
											/>
										</th>
										<th style={{padding:0}}>
											<Autocomplete
												options={[...new Set(data.map(data => data.engineeringSta))].filter(row => row).map(engineeringSta => ({ label: engineeringSta}))}
												onChange={(event, value) => handleChange('engineeringSta', value ? value.label : '')}
												value={filters.engineeringSta === '' ? null : { label: filters.engineeringSta }}
												sx={filtersStyle}
											/>
										</th>
										<th style={{padding:0}}>
											<Autocomplete
												options={[...new Set(data.map(data => data.engineModel))].filter(row => row).map(engineModel => ({ label: engineModel}))}
												onChange={(event, value) => handleChange('engineModel', value ? value.label : '')}
												value={filters.engineModel === '' ? null : { label: filters.engineModel }}
												sx={filtersStyle}
											/>
										</th>
										<th style={{padding:0}}>
											<Autocomplete
												options={[...new Set(data.map(data => data.engineThrust))].filter(row => row).map(engineThrust => ({ label: engineThrust}))}
												onChange={(event, value) => handleChange('engineThrust', value ? value.label : '')}
												value={filters.engineThrust === '' ? null : { label: filters.engineThrust }}
												sx={filtersStyle}
											/>
										</th>
										<th style={{padding:0}}>
											<Autocomplete
												options={[...new Set(data.map(data => data.apuModel))].filter(row => row).map(apuModel => ({ label: apuModel}))}
												onChange={(event, value) => handleChange('apuModel', value ? value.label : '')}
												value={filters.apuModel === '' ? null : { label: filters.apuModel }}
												sx={filtersStyle}
											/>
										</th>
										<th style={{padding:0}}>
											<Input
												value={filters.acAccount}
												onChange={(event) => handleChange('acAccount', event.target.value)}
												sx={filtersStyle}
											/>
										</th>
										<th style={{padding:0}}>
											<Input
												value={filters.componentAccount}
												onChange={(event) => handleChange('componentAccount', event.target.value)}
												sx={filtersStyle}
											/>
										</th>
										<th style={{padding:0}}>
											<Input
												value={filters.acOwner}
												onChange={(event) => handleChange('acOwner', event.target.value)}
												sx={filtersStyle}
											/>
										</th>
										<th style={{padding:0}}>
											<Input
												value={filters.componentOwner}
												onChange={(event) => handleChange('componentOwner', event.target.value)}
												sx={filtersStyle}
											/>
										</th>
										<th style={{padding:0}}>
											<Input
												value={filters.remarks}
												onChange={(event) => handleChange('remarks', event.target.value)}
												sx={filtersStyle}
											/>
										</th>
										<th style={{backgroundColor:'#d6d3ce'}}></th>
										<th style={{padding:0}}>
											<Input
												value={filters.tcDocNumber}
												onChange={(event) => handleChange('tcDocNumber', event.target.value)}
												sx={filtersStyle}
											/>
										</th>
										<th style={{backgroundColor:'#d6d3ce'}}></th>
										<th style={{padding:0}}>
											<Input
												value={filters.manualsPath}
												onChange={(event) => handleChange('manualsPath', event.target.value)}
												sx={filtersStyle}
											/>
										</th>
										<th style={{backgroundColor:'#d6d3ce'}}></th>
									</tr>
								)}
							</thead>
							<tbody>
								{filteredData.length == 0 && (
									<th colSpan={999}>
										<td style={{...rowStyle, textAlign:'center'}}>No Records Where Found</td>
									</th>
								)}
								{filteredData.map((row) => (
									<tr
										key={row.id}
										onClick={() => handleRowSelection(row)}
										style={{ 
											backgroundColor: selectedRow.id === row.id ? '#00cfd6' : 'white',
											color: selectedRow.id === row.id ? 'white' : 'black',
										}}
									>
										<td style={{...rowStyle, backgroundColor: selectedRow.id === row.id ? '#00cfd6' :'#c6c3c6', zIndex: 1}}>{row.id}</td>
										<td style={rowStyle}>{row.serialNumber}</td>
										<td style={rowStyle}>{row.reg}</td>
										<td style={rowStyle}>{row.previousReg}</td>
										<td style={rowStyle}>{row.deliverDate?.slice(0, 10)}</td>
										<td style={rowStyle}>{row.deliverHours}</td>
										<td style={rowStyle}>{row.deliverCycles}</td>
										<td style={rowStyle}>{row.pax}</td>
										<td style={rowStyle}>{row.mtow}</td>
										<td style={rowStyle}>{row.certifiedTOW}</td>
										<td style={rowStyle}>{row.mtxw}</td>
										<td style={rowStyle}>{row.selcalCode}</td>
                                        <td style={rowStyle}>{row.modeAddress}</td>
                                        <td style={rowStyle}>{row.dfdauFrame}</td>
                                        <td style={rowStyle}>{row.atcCode}</td>
                                        <td style={rowStyle}>{row.operatorICAO}</td>
                                        <td style={rowStyle}>{row.operatorName}</td>
                                        <td style={rowStyle}>{row.operatorStaLocation}</td>
                                        <td style={rowStyle}>{row.aircraftStaLocation}</td>
                                        <td style={rowStyle}>{row.operatorICAOPrevious}</td>
                                        <td style={rowStyle}>{row.operatorNamePrevious}</td>
                                        <td style={rowStyle}>{row.mroCode}</td>
                                        <td style={rowStyle}>{row.mroName}</td>
                                        <td style={rowStyle}>{row.staMRO}</td>
                                        <td style={rowStyle}>{row.engineeringCode}</td>
                                        <td style={rowStyle}>{row.engineeringName}</td>
                                        <td style={rowStyle}>{row.engineeringSta}</td>
                                        <td style={rowStyle}>{row.engineModel}</td>
                                        <td style={rowStyle}>{row.engineThrust}</td>
                                        <td style={rowStyle}>{row.apuModel}</td>
                                        <td style={rowStyle}>{row.acAccount}</td>
                                        <td style={rowStyle}>{row.componentAccount}</td>
                                        <td style={rowStyle}>{row.acOwner}</td>
                                        <td style={rowStyle}>{row.componentOwner}</td>
                                        <td style={rowStyle}>{row.remarks}</td>
                                        <td style={rowStyle}>{row.active}</td>
                                        <td style={rowStyle}>{row.tcDocNumber}</td>
                                        <td style={{...rowStyle, textAlign:'center' }}> 
											<Checkbox
												variant="outlined"
												color="neutral"
												checked={row.manuals}
												sx={{
													'& .css-r4cpcz-JoyCheckbox-checkbox': {
														borderRadius:0,
														'--joy-palette-neutral-outlinedBorder':'black',
													},
													'& .css-n110n' : {
														borderRadius:0,
														'--joy-palette-neutral-outlinedBorder':'black',
													},
												}}
											/>
										</td>
                                        <td style={rowStyle}>{row.manualsPath}</td>
										<td style={{...rowStyle, textAlign:'center' }}> 
											<Checkbox
												variant="outlined"
												color="neutral"
												//checked={row.isLumpActive}
												sx={{
													'& .css-r4cpcz-JoyCheckbox-checkbox': {
														borderRadius:0,
														'--joy-palette-neutral-outlinedBorder':'black',
													},
													'& .css-n110n' : {
														borderRadius:0,
														'--joy-palette-neutral-outlinedBorder':'black',
													},
												}}
											/>
										</td>
										
									</tr>
								))}
							</tbody>
						</Table>
					</Sheet>
				</Box>
				{showEditor && (
					<Box
						component="main"
						className="MainContent"
						sx={{
							px:1,
							pb: 1,
							flex: 1,
							display: 'flex',
							flexDirection: 'column',
							minWidth: 0,
							height: '85dvh',
							gap: 1,
						}}
					>
						<AddEditeForm
							selectedRow={selectedRow} 
							reloadTable={fetchData}
							setResponseStatus={setResponseStatus}
							SN={SN}
                            operators={operators}
                            MRO={MRO}
							sta={sta}
						/>
					</Box>
				)}
			</Box>
		</React.Fragment>
	);
}
