import * as React from 'react';

import Box from '@mui/joy/Box';
import Grid from '@mui/joy/Grid';
import Input from '@mui/joy/Input';
import Button from '@mui/joy/Button';
import Tooltip from '@mui/joy/Tooltip';
import Textarea from '@mui/joy/Textarea';
import Checkbox from '@mui/joy/Checkbox';
import FormLabel from '@mui/joy/FormLabel';
import Typography from '@mui/joy/Typography';
import IconButton from '@mui/joy/IconButton';
import FormControl from '@mui/joy/FormControl';
import Autocomplete from '@mui/joy/Autocomplete';

import GroupIcon from '@mui/icons-material/Group';

import AdditionalInfoWnd from './Additional_Info_wnd.tsx';
import AircraftsUtilites from './Security_Users_Aircrafts_Utility.tsx';

import URL from '../../../../../../URL.js';

export default function AddEditForm(props) {
	//Стили
	const inputStyle = { borderRadius: 0, mx: 1, height: 30, minHeight: 0, border:'1px solid black', color:'#000084', fontWeight:'bold', fontSize:'14px' }
	const labelStyle = { mx: 1, my: 0, p: 0,fontSize:'14px' }
	const formControlStyle = { my: 1 }
	
	const [families, setFamilies] = React.useState<{accessGranted: boolean, aircraftFamily: string}[]>([])
	const [openAircraftsUtilites, setOpenAircraftsUtilites] = React.useState(false);
	const [editedData, setEditedData] = React.useState(props.selectedRow);
	const [additionalInfo, setAdditionalInfo] = React.useState({open:false,item:''});

	React.useEffect(() => {
		setEditedData(props.selectedRow);
	}, [props.selectedRow]);

	//Запрос списка Families
	React.useEffect(() => {
		const fetchFamilies= async () => {
			if(editedData.icao && editedData.user){
				const response = await fetch(`${URL}${process.env.REACT_APP_API_ADMIN}/UsersFleets/Families()?user='${editedData.user}'&icaoCode='${editedData.icao}'`, { headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
				if (response.ok) {
					const result = await response.json();
					setFamilies(result);
				} else {
					props.setResponseStatus({...props.responseStatus, errorStatus:response.status});
				}
			}
		};
		fetchFamilies();
	}, [props.selectedRow, editedData.icao, editedData.user]);

	//Обработчик события добавления новой записи
	const handleAdd =() => {
		fetch(
			`${URL}${process.env.REACT_APP_API_ADMIN}/UsersFleets`, 
			{
				method: 'POST',
				headers: {
				  'Authorization': `Bearer ${localStorage.getItem('asid')}`,
				  'Content-Type': 'application/json'
				},
				body: JSON.stringify({
					user: editedData.user,
					userName: editedData.user ? props.users.find(user => user.login === editedData.user)?.name : null,
					operatorID: editedData.icao ? props.operators.find(operator => operator.icao === editedData.icao)?.id : null,
					aircraftFamily: editedData.icao ? props.operators.find(operator => operator.icao === editedData.icao)?.name : null,
					activate: editedData.activate,
					remarks:editedData.remarks
				})
			}
		)
		.then((response) => {
			if (!response.ok){
				props.setResponseStatus({...props.responseStatus, errorStatus:response.status});
			} else {
				props.setResponseStatus({...props.responseStatus, successfulStatus:'Add'});
			}
			props.reloadTable();
			return response.json();
		})
		.catch(error => console.error('Ошибка создания группы:', error));

	}

	//Обработчик события обновления выбранной записи
	const handleUpdate =() => {
		fetch(
			`${URL}${process.env.REACT_APP_API_ADMIN}/UsersFleets/${props.selectedRow.id}`, 
			{
				method: 'PATCH',
				headers: {
				  'Authorization': `Bearer ${localStorage.getItem('asid')}`,
				  'Content-Type': 'application/json'
				},
				body: JSON.stringify({
					idUser: editedData.user ? props.users.find(user => user.login === editedData.user)?.id : null,
					user: editedData.user,
					userName: editedData.user ? props.users.find(user => user.login === editedData.user)?.name : null,
					operatorID: editedData.icao ? props.operators.find(operator => operator.icao === editedData.icao)?.id : null,
					aircraftFamily: editedData.icao ? props.operators.find(operator => operator.icao === editedData.icao)?.name : null,
					activate: editedData.activate,
					remarks:editedData.remarks
				})
			}
		)
		.then((response) => {
			if (!response.ok){
				props.setResponseStatus({...props.responseStatus, errorStatus:response.status});
			} else {
				props.setResponseStatus({...props.responseStatus, successfulStatus:'Update'});
			}
			props.reloadTable();
			return response.json();
		})
		.catch(error => console.error('Ошибка создания группы:', error));

	}

	//Обработчик события удаления выбранной записи
	const handleDelete =() => {
		fetch(
			`${URL}${process.env.REACT_APP_API_ADMIN}/UsersFleets/${props.selectedRow.id}`, 
			{
				method: 'DELETE',
				headers: {
				  'Authorization': `Bearer ${localStorage.getItem('asid')}`,
				  'Content-Type': 'application/json'
				}
			}
		)
		.then((response) => {
			if (!response.ok){
				props.setResponseStatus({...props.responseStatus, errorStatus:response.status});
			} else {
				props.setResponseStatus({...props.responseStatus, successfulStatus:'Delete'});
			}
			props.reloadTable();
			return response.json();
		})
		.catch(error => console.error('Ошибка создания группы:', error));

	};

	//Внесение изменений в data
	const handleChange = (property, value) => {
		setEditedData({ ...editedData, [property]: value });
	};

	//Изменение чекбоксов Aircraft Family
	const handleChangeAircraftFamily = (property, value) => {
		if (property === 'ALL' && value === true) {
			const updatedFamilies = families.map(family => {
				if (family.aircraftFamily !== 'ALL') {
					return { ...family, accessGranted: false };
				}
				return { ...family, accessGranted: true };
			});
			setFamilies(updatedFamilies);
		} else {
			const index = families.findIndex(family => family.aircraftFamily === property);
			const indexAll = families.findIndex(family => family.aircraftFamily === 'ALL');
			if (index !== -1) {
				let updatedFamily = { ...families[index], accessGranted: value };
				let updatedFamilies = [...families.slice(0, index), updatedFamily, ...families.slice(index + 1)];
				if (updatedFamilies[indexAll].accessGranted) {
					updatedFamily = { ...updatedFamilies[indexAll], accessGranted: false };
					updatedFamilies = [...updatedFamilies.slice(0, indexAll), updatedFamily, ...updatedFamilies.slice(indexAll + 1)];
				}
				setFamilies(updatedFamilies);
			}
		}
	};

	const handleOpenAircraftsUtilites = () => {
		if(props.selectedRow.id != 0){
			setOpenAircraftsUtilites(true);
		}
	};

	//Обработчик открытия окна AdditionalInfo
	const handleAdditionalInfo = (event,item) => {
		if (event.code === 'F1') { 
			event.preventDefault(); 
			if (editedData[item]) {
				setAdditionalInfo({open:true, item:item})
			}
		}
	};

	return (
		<Box>
			{openAircraftsUtilites &&(
				<AircraftsUtilites setOpen={setOpenAircraftsUtilites} selectedUser={editedData} setResponseStatus={props.setResponseStatus}/>
			)}
			{additionalInfo.open && (
				<AdditionalInfoWnd item={additionalInfo.item} value={editedData[additionalInfo.item]} setAdditionalInfo={setAdditionalInfo}/>
			)}
			<Typography 
				sx={{ 
					ml: 1,
					fontSize:'18px',
					fontWeight:'bold',
					color:'#000078',
					fontFamily:'Arial, sans-serif'
				}}
			>
				Editore User-Operator-AC Family:
			</Typography>
			<Tooltip title='Add'>
				<IconButton
					variant='plain'
					disabled={editedData.user && editedData.icao ? false : true}
					onClick={handleAdd}
					sx={{
						m:0,
						'&:disabled img': {
							opacity: 0.5,
						}
					}}
				>
					<img 
						width='35px' 
						height='35px' 
						src="images/svg/add.svg" 
						alt="" 
					/>
				</IconButton>
			</Tooltip>
			<Tooltip title='Update'>
				<IconButton 
					variant='plain'
					disabled={props.selectedRow.id && editedData.user && editedData.icao ? false : true}
					onClick={handleUpdate}
					sx={{
						m:0,
						'&:disabled img': {
							opacity: 0.5,
						}
					}}
				>
					<img 
						width='35px' 
						height='35px' 
						src="images/svg/save.svg" 
						alt="" 
					/>
				</IconButton>
			</Tooltip>
			<Tooltip title='Delete'>
				<IconButton 
					variant='plain'
					disabled={props.selectedRow.id ? false : true}
					onClick={handleDelete}
					sx={{
						m:0,
						'&:disabled img': {
							opacity: 0.5,
						}
					}}
				>
					<img 
						width='35px' 
						height='35px'  
						src="images/svg/trashcan.svg" 
						alt="" 
					/>
				</IconButton>
			</Tooltip>
			<Grid container spacing={0} sx={{ flexGrow: 1 }}>
				<Grid xs={4}>
					<FormControl sx={formControlStyle} required>
						<FormLabel sx={labelStyle}> 
							User ID:
						</FormLabel>
						<Autocomplete
							options={props.users.map(data=> ({ label: data.login }))}
							value={{label: editedData.user || ''}}
							sx={{
								...inputStyle, 
								backgroundColor:'#c6ffff',
								color:'#000078',
							}}
							onChange={(event, value) => handleChange('user', value ? value.label : '')}
							onKeyDown={(event) => handleAdditionalInfo(event, 'user')}
						/>
					</FormControl>
				</Grid>
				<Grid xs={4}>
				</Grid>
				<Grid xs={4}>
					<Checkbox 
						label="Activate"
						variant="outlined"
						color='danger'
						defaultChecked 
						checked={editedData.activate == 'Y' ? true:false}
						onChange={(event) => handleChange('activate', event.target.checked ? 'Y' : 'N')}
						sx={{
							mt:4,
							color:'#840000',
							fontWeight:'bold'
						}}
					/>
				</Grid>
				<Grid xs={12}>
					<FormControl sx={formControlStyle}>
						<FormLabel sx={labelStyle}>
							User Name: 
						</FormLabel>
						<Input 
							size="sm" 
							disabled
							sx={{
								...inputStyle, 
								'--variant-outlinedDisabledColor':'black',
								'--variant-outlinedDisabledBorder':'black',
								bgcolor:'#d6d3ce',
							}} 
							value={editedData.user ? props.users.find(user => user.login === editedData.user)?.name : ''}
						/>
					</FormControl>
				</Grid>
				<Grid xs={6}>
					<FormControl sx={formControlStyle} required>
						<FormLabel sx={labelStyle}> 
							Operator Code ICAO:
						</FormLabel>
						<Autocomplete
							options={props.operators.map(data=> ({ label: data.icao }))}
							value={{label: editedData.icao || ''}}
							sx={{
								...inputStyle, 
								backgroundColor:'#c6ffff',
								color:'#000078',
							}}
							onChange={(event, value) => handleChange('icao', value ? value.label : '')}
							onKeyDown={(event) => handleAdditionalInfo(event, 'icao')}
						/>
					</FormControl>
				</Grid>
				<Grid xs={12}>
					<FormControl sx={formControlStyle}>
						<FormLabel sx={labelStyle}>
							Operator Name: 
						</FormLabel>
						<Input 
							size="sm" 
							disabled
							sx={{
								...inputStyle, 
								'--variant-outlinedDisabledColor':'black',
								'--variant-outlinedDisabledBorder':'black',
								bgcolor:'#d6d3ce',
							}} 
							value={editedData.icao ? props.operators.find(operator => operator.icao === editedData.icao)?.name : ''}
						/>
					</FormControl>
				</Grid>
				<Grid xs={6}>
					<FormControl sx={formControlStyle} required>
						<FormLabel sx={labelStyle}> 
							Aircraft Family:
						</FormLabel>
						<Box
							sx={{
								display:'flex',
								flexDirection:'column',
								height:'150px',
								overflow:'auto',
								p:1,
								mx:1,
								border:'1px solid black',
								bgcolor:'white'
							}}
						>
							{editedData.icao && families && (families.map((row) => (
								<Checkbox
									label={row.aircraftFamily}
									color='neutral'
									variant='outlined'
									checked={row.accessGranted}
									onChange={(event) => handleChangeAircraftFamily(row.aircraftFamily, event.target.checked)}
									sx={{
										fontSize:'18px',
										mb:0.5,
										'& .css-r4cpcz-JoyCheckbox-checkbox': {
											borderRadius:0,
											'--joy-palette-neutral-outlinedBorder':'black',
										},
										'& .css-n110n' : {
											borderRadius:0,
											'--joy-palette-neutral-outlinedBorder':'black',
										},

									}}
								/>
							)))}	
						</Box>
					</FormControl>
				</Grid>
				<Grid xs={6}>
					<Button 
						color = "neutral" 
						variant='outlined' 
						startDecorator={<GroupIcon sx={{color:'black'}}/>}
						onClick={handleOpenAircraftsUtilites}
						sx={{
							mt:3.6,
							fontSize:'14px',
							color:'black',
							borderRadius:'0px',
							borderLeft:'1px solid white',
							borderTop:'1px solid white',
							borderRight:'1px solid black',
							borderBottom:'1px solid black',
							minWidth:0,
							width:'100%',
							pl:1,
							pr:0.2,
							py:1
						}}
					>
						User rights on aircrafts
					</Button>
				</Grid>

				<Grid xs={12}>
					<FormControl sx={formControlStyle}>
						<FormLabel sx={labelStyle}> 
							Remarks:
						</FormLabel>
						<Textarea 
							maxRows={10}
							defaultValue={props.selectedRow.description}
							sx={{
								...inputStyle, 
								height:'250px',
								color:'#000078',
								lineHeight: '1.2'
							}}
							value={editedData?.remarks || ''}
							onChange={(event) => handleChange('remarks', event.target.value)}
						/>
					</FormControl>
				</Grid>
			</Grid>
		</Box>
	);
}
